<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Делаем трессы</h3>
        </div>
                
        <VideoView video-id="e140f98256e54aabbf2de1c9f7b4bcf9"/>

        <div class="MBtextcont">
            <h3>2. Подготовка</h3>
        </div>

        <VideoView video-id="a016547e711a41e0b6aa95e8d887d746"/>

        <div class="MBtextcont">
            <h3>3.1 Выкладываем трессы на нижней части головы </h3>
        </div>
                
        <VideoView video-id="2247308521a1456d92f13cc7c8e33817"/>

        <div class="MBtextcont">
            <h3>3.2 Выкладываем трессы на макушке и верхней части</h3>
        </div>
                
        <VideoView video-id="f4ed5006251249688c96505b00be65b0"/>

        <div class="MBtextcont">
            <h3>4. Оформляем прическу</h3>
        </div>          

        <VideoView video-id="31b3c0ff018f4fa58cc4e1ad01141757"/>


        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Сделать трессы</li>
                <li>Создать парик</li>
                <li>Оформить прическу</li>
                <li><b>Подготовить материалы</b> к следующему занятию:</li>
                <ol type="a">
                    <li>Наполнитель для тела (синтепон или опилки)</li>
                    <li>Плотный трикотаж телесного цвета + нитки в цвет</li>
                    <li>Кружева для украшения тела</li>
                </ol>
            </ol>

           <h3>Время выполнения: 6 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>